<template>
	<div class="filter-container-test">
		<TestFilterTag
			name="inProgress"
			label="À faire"
			bgColor="#686bdf"
			:active="filterTestStatus.inProgress"
			class="mr-3 mt-3"
			:onClick="toogleFilterTestStatus"
		/>
		<TestFilterTag
			name="scheduled"
			label="Programmé"
			bgColor="#3676CB"
			:active="filterTestStatus.scheduled"
			class="mr-3 mt-3"
			:onClick="toogleFilterTestStatus"
		/>
		<TestFilterTag
			name="completed"
			label="Terminé"
			bgColor="#12c399"
			:active="filterTestStatus.completed"
			class="mr-3 mt-3"
			:onClick="toogleFilterTestStatus"
		/>
		<TestFilterTag
			name="notComplete"
			label="Non complété"
			bgColor="#ff9800"
			:active="filterTestStatus.notComplete"
			class="mr-3 mt-3"
			:onClick="toogleFilterTestStatus"
		/>
	</div>
</template>

<script>
import TestFilterTag from './TestFilterTag.vue';

export default {
	name: 'TestFilterTags',
	components: {
		TestFilterTag,
	},
	props: {
		filterTestStatus: Object,
		toogleFilterTestStatus: Function,
	},
};
</script>

<style scoped lang="scss">
.filter-container-test {
	display: flex;
	flex-wrap: wrap;
}
</style>
