<template>
	<div v-if="active" :style="`background-color:${bgColor}`" label class="test-filter-tag" @click="handleClick">
		<div class="test-filter-tag-div">
			<span>{{ label }}</span>
			<span>
				<v-icon start color="white" class="pl-2 cursor-pointer">mdi-close</v-icon>
			</span>
		</div>
	</div>
	<div v-else label class="test-filter-tag inactive cursor-pointer" @click="handleClick">
		{{ label }}
	</div>
</template>

<script>
export default {
	name: 'TestFilterTag',
	props: {
		name: String,
		label: String,
		bgColor: String,
		active: Boolean,
		onClick: Function,
	},
	methods: {
		handleClick() {
			this.onClick(this.name);
		},
	},
};
</script>

<style scoped lang="scss">
.test-filter-tag-div {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.test-filter-tag {
	font-size: 15px;
	font-weight: 500;
	min-height: 32.5px;
	color: white;
	padding: 3px 10px;
	border-radius: 5px;
	display: inline;
	border: 1px solid transparent;
	cursor: pointer;
}
.inactive {
	border-color: black;
	color: black;
}
</style>
