<template>
	<v-container>
		<div class="test-home-page">
			<h2 v-if="showMessageForWhenNoFormation" class="hello">Bonjour {{ profileInfos.first_name }},</h2>
			<h2 v-else class="hello">Mes défis</h2>
			<div v-if="showMessageForWhenNoFormation" class="gray-box-text mt-3">
				Bienvenue dans ton espace personnel, où tu retrouveras l'ensemble de tes tests. Tu peux consulter tes résultats
				et revoir la correction de chaque question.
			</div>
			<UserTestsList v-if="this.profileInfos._id" class="mt-5" :userId="this.profileInfos._id" />
		</div>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import UserTestsList from '@/components/user-tests-list/UserTestsList.vue';

export default {
	name: 'TestHomePage',
	components: { UserTestsList },
	computed: {
		...mapGetters('profile', { profileInfos: 'profileInfos' }),
	},
	data() {
		return {
			showMessageForWhenNoFormation: false,
		};
	},
	async mounted() {
		window.scrollTo(0, 0);
	},
	async created() {
		if (this.profileInfos.first_name == null) await this.$store.dispatch('profile/getUserProfile');

		const formations = await this.$store.dispatch('formation/getAllFormationsOfUser');
		if (formations.length === 0) this.showMessageForWhenNoFormation = true;
	},
};
</script>

<style scoped lang="scss">
.hello {
	font-size: 2.3rem;
}
.gray-box-text {
	padding: 10px 12px;
	border-radius: 4px;
	background-color: #e6e6e6;
}
.test-home-page {
	margin-bottom: 50px;
}
</style>
