<template>
	<v-card class="test-card shadow-blue-not-openned mx-auto">
		<div class="image-block">
			<img class="main-cover-photo align-end" :style="{ backgroundImage: 'url(' + testBank.main_cover_photo + ')' }" />
			<v-chip
				v-if="test.status === TEST_STATUS.inProgress && test.due_date"
				class="status-label"
				label
				:color="'#686bdf'"
			>
				Temps restant : {{ timeBeforeTestEnd(test.due_date) }}
			</v-chip>
			<v-chip
				v-else-if="test.status === TEST_STATUS.inProgress && !test.due_date"
				class="status-label"
				label
				:color="'#686bdf'"
			>
				À faire
			</v-chip>
			<v-chip v-else-if="test.status === TEST_STATUS.scheduled" class="status-label" label :color="'#3676CB'">
				Accessible dans : {{ timeBeforeTeststart(test.begin_date) }}
			</v-chip>
			<v-chip v-else-if="test.status === TEST_STATUS.notComplete" class="status-label" label :color="'#ff9800'">
				Non complété
			</v-chip>
			<v-chip v-else-if="test.status === TEST_STATUS.completed" class="status-label" label :color="'#12c399'">
				Terminé
			</v-chip>
		</div>

		<div class="card-content-wrapper">
			<h2 class="test-bank-name mt-3 mb-3">{{ testBank.name | tronc }}</h2>

			<!-- SCORE BLOCK -->
			<TestScoreBlock :status="test.status" :userId="userId" :test="test" :lastTestScore="lastTestScore" />

			<!-- PASSED DATE -->
			<div v-if="test.status === TEST_STATUS.inProgress" class="passed-date-block">
				Date de passage : <span class="font-weight-bold">En attente</span>
			</div>
			<div v-else-if="test.status === TEST_STATUS.scheduled" class="passed-date-block">
				Date de passage : <span class="font-weight-bold">-</span>
			</div>
			<div
				v-else-if="test.status === TEST_STATUS.completed || test.status === TEST_STATUS.notComplete"
				class="passed-date-block"
			>
				Date de passage :
				<span class="font-weight-bold">{{
					lastTestScore ? formatPassedTime(lastTestScore.last_answered_time) : '-'
				}}</span>
			</div>
		</div>

		<v-card-actions>
			<v-btn
				v-if="test.status === TEST_STATUS.inProgress"
				class="w-100 mt-3 mb-2 action-btn"
				dark
				color="black"
				:to="{ name: 'DoTestPage', params: { testId: test._id, userId: userId } }"
			>
				Commencer
			</v-btn>
			<div class="see-test-report-container" v-else-if="test.status === TEST_STATUS.completed">
				<v-btn
					outlined
					class="mt-3 mb-2 action-btn"
					:to="{ name: 'TestResultPage', params: { testScoreId: lastTestScore._id } }"
				>
					<v-icon size="large" class="mr-1">mdi-eye-outline</v-icon> Revoir mon rapport
				</v-btn>

				<!-- Jade : Display only is have more attempt -->
				<v-btn
					v-if="(isTestDue !== true && testScores.length < test.attempts) || test.attempts == null"
					outlined
					class="ml-1 mt-3 mb-2 action-btn"
					:to="{ name: 'DoTestPage', params: { testId: test._id, userId: userId } }"
				>
					<v-icon size="large" class="mr-1">mdi-refresh</v-icon>
				</v-btn>
			</div>
			<v-btn v-else-if="test.status === TEST_STATUS.scheduled" class="action-btn no-btn w-100 mt-3 mb-2"></v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import { TEST_STATUS } from '@/constants/test.js';
import TestScoreBlock from './TestScoreBlock.vue';
import { getLastTestScore } from '@/utils/testScore.js';

export default {
	name: 'TestCard',
	components: { TestScoreBlock },
	props: {
		userId: String,
		test: Object,
		testBank: Object, // nullable
		testScores: Array, // nullable | empty array
	},
	data() {
		return {
			TEST_STATUS: TEST_STATUS,
			lastTestScore: null,
			candidateInTest: null,
		};
	},
	computed: {
		isTestDue() {
			const testDueDate = this.candidateInTest.special_due_date || this.test.due_date;
			if (testDueDate == null) return false;
			return moment().diff(moment(testDueDate)) >= 0;
		},
	},
	async created() {
		this.candidateInTest = await this.fetchCandidateInTest();

		const _lastTestScore = getLastTestScore(this.testScores);
		if (_lastTestScore) this.lastTestScore = _lastTestScore;
	},
	filters: {
		tronc(value) {
			if (value.length > 55) {
				return value.slice(0, 55) + '...';
			} else {
				return value;
			}
		},
	},
	methods: {
		timeBeforeTeststart(beginDate) {
			const duration = moment.duration(moment(beginDate).diff(moment()));
			const days = Math.floor(duration.asDays());
			const hours = duration.hours();
			const minutes = duration.minutes();
			if (days > 0) return `${days}j ${hours}h ${minutes}min`;
			if (hours > 0) return `${hours}h ${minutes}min`;
			if (minutes > 0) return `${minutes}min`;
		},
		timeBeforeTestEnd(dueDate) {
			const duration = moment.duration(moment(dueDate).diff(moment()));
			const days = Math.floor(duration.asDays());
			const hours = duration.hours();
			const minutes = duration.minutes();
			if (days > 0) return `${days}j ${hours}h ${minutes}min`;
			if (hours > 0) return `${hours}h ${minutes}min`;
			if (minutes > 0) return `${minutes}min`;
		},
		formatPassedTime(time) {
			return moment(time).format('DD/MM/YYYY à HH:mm');
		},
		async fetchCandidateInTest() {
			const res = await axios.get(`/api/tests/candidates/${this.test._id}/${this.userId}`);
			return res.data.candidateInTest;
		},
	},
};
</script>

<style scoped lang="scss">
.see-test-report-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.test-card {
	border-radius: 10px;
	width: 100% !important;
	display: flex;
	flex-direction: column;
	height: 500px;
}

.image-block {
	position: relative;

	.status-label {
		position: absolute;
		top: 7px;
		right: 7px;
		color: white;
		font-weight: bold;
		font-size: 15px;
	}
	.main-cover-photo {
		width: 100%;
		height: 200px;
		background-size: cover;
		background-position: center;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
}

.test-bank-name {
	text-align: center;
	height: 67px;
}

.score-block {
	display: flex;
	justify-content: space-around;
	.score,
	.rank {
		width: 50%;
		text-align: center;
	}
	.rank {
		border-left: 1px solid black;
	}
}

.passed-date-block {
	text-align: center;
}
.no-btn {
	cursor: default;
	pointer-events: none;
	opacity: 0;
}
.action-btn {
	min-height: 50px;
	flex-grow: 1;
}

.v-card-actions {
	margin-top: auto;
}

.card-content-wrapper {
	flex-grow: 1;
}
</style>
