<template>
	<div class="score-block">
		<div class="score">
			<img class="icon-score-trophy-img" src="@/assets/images/test-dashboard/score-img.png" />
			<br />
			<span v-if="status === TEST_STATUS.completed" class="font-weight-bold scoreText">
				{{ lastTestScore ? `${lastTestScore.score}%` : '-' }}
			</span>
			<span v-else-if="status === TEST_STATUS.inProgress" class="font-weight-bold">En attente</span>
			<span v-else class="font-weight-bold">-</span>
		</div>
		<template v-if="test.total_candidates > 1">
			<div class="rank">
				<img class="icon-score-trophy-img" src="@/assets/images/test-dashboard/trophy-img.png" />
				<br />
				<v-skeleton-loader v-if="loadingRanking" class="mt-2" width="50" type="text" />
				<span v-else-if="status === TEST_STATUS.inProgress" class="font-weight-bold">En attente</span>
				<span v-else-if="status === TEST_STATUS.completed" class="font-weight-bold scoreText">{{ userRank }}</span>
				<span v-else class="font-weight-bold">-</span>
			</div>
		</template>
	</div>
</template>

<script>
import { TEST_STATUS } from '@/constants/test.js';

export default {
	name: 'TestScoreBlock',
	props: {
		status: String,
		userId: String,
		test: Object,
		lastTestScore: Object, // nullable | TestScore
	},
	data() {
		return {
			TEST_STATUS: TEST_STATUS,
			loadingRanking: false,
			userRank: '',
		};
	},
	async created() {
		if (this.test.total_candidates === 1) return;
		if (this.status !== TEST_STATUS.completed || this.status !== TEST_STATUS.notComplete)
			await this.getUserTestRank(this.userId, this.test._id);
	},
	methods: {
		async getUserTestRank(userId, testId) {
			this.loadingRanking = true;

			const allTestScoresOfTest = await this.$store.dispatch('testScores/fetchTestScores', {
				testId,
				projectFields: { score: 1, last_answered_time: 1, status: 1, test_id: 1, user_id: 1, attempt: 1 },
			});

			// Filter out scores with status 'not completed'
			const validTestScores = allTestScoresOfTest.filter((testScore) => testScore.status === 'completed');

			const otherCandidateScores = validTestScores.filter((testScore) => testScore.user_id !== userId).length;

			if (otherCandidateScores === 0) {
				this.userRank = 'En attente';
			} else {
				const lastAttemptTestScores = []; // Filtered all passed scores of all candidates in the test

				validTestScores.forEach((testScore) => {
					const existIndex = lastAttemptTestScores.findIndex((t) => t.user_id === testScore.user_id);
					if (existIndex === -1) {
						lastAttemptTestScores.push(testScore);
					} else if (testScore.attempt > lastAttemptTestScores[existIndex].attempt) {
						lastAttemptTestScores[existIndex] = testScore;
					}
				});

				lastAttemptTestScores.sort((a, b) => b.score - a.score); // Sort score DESC. At index 0 is rank 1;

				const myIndex = lastAttemptTestScores.findIndex((t) => t.user_id === userId);
				this.userRank = `${myIndex + 1}/${lastAttemptTestScores.length}`;
			}
			this.loadingRanking = false;
		},
	},
};
</script>

<style scoped lang="scss">
.score-block {
	display: flex;
	justify-content: space-around;
	margin-bottom: 10px;
	.score,
	.rank {
		width: 50%;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.rank {
		border-left: 1px solid black;
	}
	.icon-score-trophy-img {
		width: 23px;
	}
	.scoreText {
		font-size: 25px;
	}
}
</style>
